var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:{negative: _vm.negative},staticStyle:{"z-index":"9999"},attrs:{"fluid":"","tag":"section"}},[_c('v-tabs',{style:(("position:relative;font-size:" + _vm.zoom + "%;")),attrs:{"grow":"","show-arrows":"","id":"liveview","dark":_vm.negative},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.tabs),function(t){return _c('v-tab',{key:t.key,attrs:{"href":("#" + (t.href))},on:{"click":_vm.stopauto}},[_vm._v(" "+_vm._s(t.title)+" ")])}),_vm._l((_vm.menu),function(t){return _c('v-tab',{key:t.key,staticStyle:{"display":"none"},attrs:{"href":("#" + (t.href))}},[_vm._v(" "+_vm._s(t.title)+" ")])}),(_vm.menu.length)?_c('v-menu',{attrs:{"bottom":"","left":"","attach":"#liveview"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-center mr-4",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" mehr... "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,1151984631)},[_c('v-list',{staticClass:"grey lighten-3"},_vm._l((_vm.menu),function(t){return _c('v-list-item',{key:t.key,on:{"click":function($event){_vm.tab = t.href}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{class:{'red--text': t.href === _vm.tab}},[_vm._v(" "+_vm._s(t.title)+" ")])]},proxy:true}],null,true)})}),1)],1):_vm._e(),(_vm.timeout !== null)?_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","small":""},on:{"click":_vm.stopauto}},on),[_c('i',{staticClass:"far fa-bolt-auto",staticStyle:{"font-size":"20px"}})])]}}],null,false,1261934984)},[_c('span',[_vm._v(" Automatik beenden ")])]):_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","small":""},on:{"click":_vm.startauto}},on),[_c('span',{staticClass:"fa-stack fa-2x"},[_c('i',{staticClass:"far fa-bolt-auto fa-stack-1x",staticStyle:{"font-size":"15px"}}),_c('i',{staticClass:"far fa-ban fa-stack-1x",staticStyle:{"font-size":"28px","color":"lightgray","line-height":"40px"}})])])]}}])},[_c('span',[_vm._v(" Automatik starten ")])]),_c('v-btn',{attrs:{"text":"","fab":"","small":""},on:{"click":function($event){_vm.negative = !_vm.negative}}},[_c('v-icon',[_vm._v(" far fa-circle-half-stroke ")])],1),_c('v-btn',{attrs:{"text":"","fab":"","small":""},on:{"click":function($event){_vm.zoom += 25}}},[_c('v-icon',[_vm._v(" far fa-magnifying-glass-plus ")])],1),_c('v-btn',{attrs:{"text":"","fab":"","small":""},on:{"click":function($event){_vm.zoom -= 25}}},[_c('v-icon',[_vm._v(" far fa-magnifying-glass-minus ")])],1),_c('v-btn',{attrs:{"text":"","fab":"","small":""},on:{"click":_vm.fullscreen}},[(!_vm.isFullscreen)?_c('v-icon',[_vm._v(" far fa-expand-wide ")]):_c('v-icon',[_vm._v(" far fa-compress-wide ")])],1),_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs.concat( _vm.menu)),function(t){return _c('v-tab-item',{key:t.key,attrs:{"value":t.href}},[(t.route === 'finished')?_c('finished',{attrs:{"id":t.id,"e":_vm.Events[t.id],"r":_vm.Results[t.id] || [],"df":_vm.DisciplineFind}}):(t.route === 'startgeraet')?_c('startgeraet',{attrs:{"es":_vm.Events,"df":_vm.DisciplineFind}}):(t.route === 'turner')?_c('turner',{attrs:{"es":_vm.Events,"rs":_vm.Results,"df":_vm.DisciplineFind}}):(t.route === 'geraet')?_c('geraet',{attrs:{"es":_vm.Events,"rs":_vm.Results,"df":_vm.DisciplineFind,"did":t.did}}):(t.route === 'uebersicht')?_c('uebersicht',{attrs:{"id":t.id,"e":_vm.Events[t.id],"r":_vm.Results[t.id] || [],"df":_vm.DisciplineFind,"negative":_vm.negative}}):_vm._e()],1)}),1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }